import React, {useState} from "react";
import {ItemDirective, ItemsDirective, ToolbarComponent, TreeViewComponent} from "@syncfusion/ej2-react-navigations";
import {DragAndDropEventArgs} from "@syncfusion/ej2-navigations/src/treeview/treeview";
import './SchemaEditorComponent.scss';
import {ButtonComponent, SwitchComponent} from "@syncfusion/ej2-react-buttons";
import {DialogComponent} from "@syncfusion/ej2-react-popups";
import {TextBoxComponent} from "@syncfusion/ej2-react-inputs";
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";

function flattenArray(result: any, data: any) {
    if (data) {
        data.forEach((item: any) => {
            result.push({
                id: item.id,
                name: item.name,
                type: item.type,
                isStructure: item.isStructure,
                isArray: item.isArray,
                isRequired: item.isRequired,
            });
            flattenArray(result, item.fields);
        })
    }
}

function flatten(data: any) {
    let result: any = [];
    flattenArray(result, data);
    return result;
}

function findById(data: any, id: any) {
    const result = flatten(data).filter((it: { id: Object; }) => it.id === id)
    return result.length === 0 ? null : result[0]
}

const SchemaEditorComponent = (props: any) => {
    const {datasource} = props;
    const [showFieldDialog, setShowFieldDialog] = useState(false);
    let buttons = [
        {
            buttonModel: {
                content: 'OK',
                cssClass: 'e-flat',
                isPrimary: true,
            },
            'click': () => {
                setShowFieldDialog(false);
            }
        },
        {
            buttonModel: {
                content: 'Cancel',
                cssClass: 'e-flat'
            },
            'click': () => {
                setShowFieldDialog(false);
            }
        }
    ];
    let schemaComponent: TreeViewComponent | null

    function fieldsTemplate() {
        return (<span style={{fontSize: "initial"}}>Fields</span>);
    }

    return (
        <>
            <ToolbarComponent>
                <ItemsDirective>
                    <ItemDirective template={fieldsTemplate}/>
                    <ItemDirective type="Separator"/>
                    <ItemDirective text="Add" prefixIcon="e-icons e-circle-add" click={() => setShowFieldDialog(true)}/>
                </ItemsDirective>
            </ToolbarComponent>
            <TreeViewComponent
                ref={(component) => {
                    schemaComponent = component as TreeViewComponent;
                }}
                style={{width: "100%", height: "100%"}}
                fields={
                    {
                        dataSource: datasource,
                        id: 'id',
                        text: 'name',
                        child: 'fields',
                        iconCss: 'icon',
                        imageUrl: 'image'
                    }
                }
                cssClass={"schema-tree"}
                allowDragAndDrop={true}
                allowMultiSelection={true}

                nodeDragging={(event: DragAndDropEventArgs) => {
                    if (event.droppedNodeData) {
                        const targetData: any = findById(datasource, event.droppedNodeData.id)
                        if (targetData && targetData.isStructure) {
                            return;
                        }
                    }
                    event.dropIndicator = 'e-no-drop';
                }}
                nodeDragStop={(event: DragAndDropEventArgs) => {
                    if (event.droppedNodeData) {
                        const targetData: any = findById(datasource, event.droppedNodeData.id)
                        if (targetData && targetData.isStructure) {
                            return;
                        }
                    }
                    event.cancel = true;
                }}
                nodeTemplate={(data: any) => {
                    return (<>
                        <div>
                            <div className="textcontent">
                                    <span><b>{data.name}</b>
                                        {data.isRequired && <span style={{color: 'red'}}>*</span>}: &nbsp;
                                        {data.isArray && <span>array of </span>}
                                        {data.isStructure && <span>{"{...}"}</span>}
                                        {data.type}
                                    </span>
                            </div>
                            <div className="commandcontainer">
                                {data.isStructure &&
                                    <ButtonComponent cssClass='e-flat' iconCss='e-icons e-circle-add'
                                                     onClick={(event) => {
                                                         const node = schemaComponent?.getNode(data.id)
                                                         console.log("Node: " + JSON.stringify(node));
                                                     }}/>
                                }
                                <ButtonComponent cssClass='e-flat' iconCss='e-icons e-edit'/>&nbsp;
                                <ButtonComponent cssClass='e-flat' iconCss="e-icons e-trash"
                                                 onClick={(event) => {
                                                     schemaComponent?.removeNodes([data.id])
                                                 }}/>
                            </div>
                        </div>
                    </>)
                }}
            >
            </TreeViewComponent>
            <DialogComponent visible={showFieldDialog}
                             close={() => setShowFieldDialog(false)}
                             width='60vh'
                             target="#app"
                             header='Edit Field'
                             isModal={true}
                             allowDragging={true}
                             enableResize={true}
                             showCloseIcon={true}
                             buttons={buttons}>
                <form className="pure-form">
                    <fieldset>
                        <legend>A compact inline form</legend>
                        <TextBoxComponent className="input-control" placeholder="Name" showClearButton={true}
                                          floatLabelType="Never"/>
                        <DropDownListComponent className="input-control" placeholder="Type" showClearButton={true}
                                               floatLabelType="Never"/>
                        <label htmlFor="default-remember">
                            <input type="checkbox" id="default-remember"/> Remember me
                        </label>
                        <button type="submit" className="pure-button pure-button-primary">Sign in</button>
                    </fieldset>
                </form>
                <div className="content-wrapper">
                    <div className="form-group">
                        <label>Name</label>
                        <TextBoxComponent className="input-control" placeholder="Name" showClearButton={true}
                                          floatLabelType="Never"/>
                        <label>Type</label>
                        <DropDownListComponent className="input-control" placeholder="Type" showClearButton={true}
                                               floatLabelType="Never"/>
                    </div>
                    <div className="form-group">
                        <label>Required</label><SwitchComponent className="input-control" checked={true}/>
                    </div>
                </div>
            </DialogComponent>
        </>
    );
}

export default SchemaEditorComponent;
