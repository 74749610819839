import React from "react";
import "./Schema.css";

const SchemaView = () => {

    return (
        <>
            <h1>Schema</h1>
        </>
    );
};

export default SchemaView;
