import React from "react";

const NotFoundView = () => {

    return (
        <>
            <h1>404 Not Found</h1>
        </>
    );
};

export default NotFoundView;
