import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {registerLicense} from '@syncfusion/ej2-base';
import {HashRouter, Route, Routes} from "react-router-dom";
import {CollectionsView} from "./views/Collections";
import SchemaView from "./views/Schema";
import NotFoundView from "./views/NotFound";
import {CollectionView} from "./views/Collection";

// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkJiWX5bcXFQT2hdVU0=');

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <HashRouter>
        <Routes>
            <Route path="/" element={<App/>}>
                <Route index element={<CollectionsView/>}/>
                <Route path="collections" element={<CollectionsView/>}/>
                <Route path="collections/:collectionName" element={<CollectionView/>}/>
                <Route path="metadata" element={<SchemaView/>}/>
                <Route path="*" element={<NotFoundView/>}/>
            </Route>
        </Routes>
    </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
