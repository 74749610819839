import axios from "axios";
import {
    DataUtil,
    ParamOption,
    Predicate,
    PvtOptions,
    QueryList,
    QueryOptions,
    RemoteOptions,
    Requests,
    UrlAdaptor
} from "@syncfusion/ej2-data";
import {DataManager} from "@syncfusion/ej2-data/src/manager";
import {Query} from "@syncfusion/ej2-data/src/query";

export const token = process.env.API_TOKEN || "Test"
export const apiBaseUrl = "/api"
export const adminBaseUrl = "/admin"
console.log("API-BaseUrl:" + apiBaseUrl);
console.log("ADMIN-BaseUrl:" + adminBaseUrl);

export const apiClient = axios.create({
    baseURL: adminBaseUrl,
    headers: {'Authorization': `bearer ${token}`}
});

apiClient.interceptors.response.use(undefined, function (error) {
    return Promise.reject(error);
})

export class MongoDBAdaptor extends UrlAdaptor {
    processQuery(dm: DataManager, query: Query, hierarchyFilters?: Object[]): Object {
        let queries: Requests = this.getQueryRequest(query);
        let singles: QueryList = Query.filterQueryLists(query.queries, ['onSelect', 'onPage', 'onSkip', 'onTake', 'onRange']);
        let params: ParamOption[] = query.params;
        let url: string | undefined = dm.dataSource.url;
        let skip: number | undefined;
        let take: number | undefined;
        let options: RemoteOptions = this.options;
        let request: Requests = {sorts: [], groups: [], filters: [], searches: [], aggregates: []};
        // calc Paging & Range
        if ('onPage' in singles) {
            let onPage = singles.onPage;
            skip = DataUtil.getValue(onPage?.pageIndex, query);
            take = DataUtil.getValue(onPage?.pageSize, query);
            // @ts-ignore
            skip = (skip - 1) * take;
        } else if ('onRange' in singles) {
            let onRange = singles.onRange;
            // @ts-ignore
            skip = onRange.start;
            // @ts-ignore
            take = onRange.end - onRange.start;
        }
        // Sorting
        queries.sorts.forEach((value, index, array) => {
            // @ts-ignore
            let queryOptions = DataUtil.getValue(value.e.fieldName, query) as QueryOptions;
            request.sorts.push(DataUtil.callAdaptorFunction(
                this, 'onEachSort', {name: queryOptions, direction: value.e?.direction}, query));
        })

        // hierarchy
        if (hierarchyFilters) {
            let temp = this.getFiltersFrom(hierarchyFilters, query);
            if (temp) {
                request.filters.push(DataUtil.callAdaptorFunction(this, 'onEachWhere', temp.toJson(), query));
            }
        }
        // Filters
        queries.filters.forEach((value, index, array) => {
            request.filters.push(DataUtil.callAdaptorFunction(this, 'onEachWhere', (value.e as Predicate)?.toJson(), query));
            let keys: string[] = typeof value === 'object' ? Object.keys(value) : [];
            for (let prop of keys) {
                // @ts-ignore
                if (DataUtil.isNull((request)[prop])) {
                    // @ts-ignore
                    delete request[prop];
                }
            }
        })
        // Searches
        queries.searches.forEach((value, index, array) => {
            let temp = value.e;
            request.searches.push(DataUtil.callAdaptorFunction(
                this, 'onEachSearch', {
                    fields: temp?.fieldNames,
                    operator: temp?.operator,
                    key: temp?.searchKey,
                    ignoreCase: temp?.ignoreCase
                },
                query));
        })
        // Grouping
        queries.groups.forEach((value, index, array) => {
            // @ts-ignore
            request.groups.push(DataUtil.getValue(value.e.fieldName, query) as QueryOptions);
        })
        // aggregates
        queries.aggregates.forEach((value, index, array) => {
            let temp = value.e;
            // @ts-ignore
            request.aggregates.push({type: temp.type, field: DataUtil.getValue(temp.field, query)});
        })
        let req: { [key: string]: Object } = {};
        (this as any).getRequestQuery(options, query, singles, request, req);
        // Params
        DataUtil.callAdaptorFunction(this, 'addParams', {dm: dm, query: query, params: params, reqParams: req});
        // cleanup
        let keys: string[] = Object.keys(req);
        for (let prop of keys) {
            if (DataUtil.isNull(req[prop]) || req[prop] === '' || req[prop] === []) {
                delete req[prop];
            }
        }
        // @ts-ignore
        if (!(options.skip in req && options.take in req) && take !== null) {
            // @ts-ignore
            req[options.skip] = DataUtil.callAdaptorFunction(this, 'onSkip', skip, query);
            // @ts-ignore
            req[options.take] = DataUtil.callAdaptorFunction(this, 'onTake', take, query);
        }
        let p: PvtOptions = this.pvt;

        let data = [];
        if (!!req["filter"]) {
            data.push({$match: {}})
        }
        if (!!req["search"]) {
            data.push({$search: {}});
        }
        if (!!req["group"]) {
            data.push({$group: {}});
        }
        if (!!req["sort"]) {
            data.push({$sort: {}});
        }
        let skip_take = [];
        if (!!req["skip"]) {
            skip_take.push({$skip: req["skip"]});
        }
        if (!!req["take"]) {
            skip_take.push({$limit: req["take"]});
        }
        const facet = {$facet: {data: skip_take, pagination: [{$count: "total"}]}}
        data.push(facet);
        this.pvt = {};
        if (this.options.requestType === 'json') {
            return {
                data: JSON.stringify(data),
                url: url,
                pvtData: p,
                type: 'POST',
                contentType: 'text/plain; charset=utf-8'
            };
        }
        let temp = this.convertToQueryString(req, query, dm);
        // @ts-ignore
        temp = (dm.dataSource.url.indexOf('?') !== -1 ? '&' : '/') + temp;
        // Here you can modify the request url as per your requirement
        return {
            type: 'GET', url: temp.length ? url?.replace(/\/*$/, temp) : url, pvtData: p
        };
    }
}
