import './App.scss';
import React, {useContext, useEffect, useState} from 'react';
import {
    DrawNodeEventArgs,
    ItemDirective,
    ItemsDirective,
    ToolbarComponent,
    TreeViewComponent
} from '@syncfusion/ej2-react-navigations';
import {PaneDirective, PanesDirective, SplitterComponent} from "@syncfusion/ej2-react-layouts";
import {matchPath, Outlet, useLocation, useNavigate} from "react-router-dom";

import TdSoftLogoImage from "./assets/TdSoftLogo.svg";
import ContentaLogoImage from "./assets/ContentaLogo.svg";
import {apiClient} from "./Api";
import {CollectionsContext} from "./contexts/collections-context";

export function genUniqueId(): string {
    const dateStr = Date
        .now()
        .toString(36); // convert num to base 36 and stringify

    const randomStr = Math
        .random()
        .toString(36)
        .substring(2, 8); // start at index 2 to skip decimal point

    return `${dateStr}-${randomStr}`;
}


const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

function TdSoftLogo() {
    return (
        <div className="div-align-center">
            <img
                src={TdSoftLogoImage}
                alt="TdSoft Logo"
                className="company-logo"
                onClick={() => openInNewTab("https://www.tdsoft.at")}
            />
            <span
                onClick={() => openInNewTab("https://www.tdsoft.at")}
                className="company-text"><b><i>TdSoft</i></b></span>
        </div>
    );
}

function ContentaLogo() {
    let navigation = useNavigate()
    return (
        <div className="div-align-center">
            <img
                src={ContentaLogoImage}
                alt="Contenta Logo"
                className="product-logo"
                onClick={() => navigation('/collections', {replace: true})}
            />
            <span
                onClick={() => {
                    navigation('/collections', {replace: true});
                }}
                className="product-text"><b>Contenta</b></span>
        </div>
    );
}

export enum NavType {
    Dashboard,
    Settings,
    Collections,
    Structures,
    Collection
}

let navComponent: TreeViewComponent | null;

const SideNav = () => {
    const location = useLocation()
    const cssClass = 'nav-tree'
    const [data, setData] = useState<{ [key: string]: Object }[]>([]);

    const collections = useContext(CollectionsContext);

    useEffect(() => {
        let children = collections.map((value: any) => {
            return {
                id: 'collection-' + value.name,
                name: value.name,
                count: value.documentCount,
                navType: NavType.Collection,
                path: '/#/collections/' + value.name,
            }
        });
        setData([
            {
                id: 'dashboard', name: 'Dashboard', expanded: true, navType: NavType.Dashboard,
                child: [{
                    id: 'collections',
                    name: 'Collections',
                    expanded: true,
                    path: '/#/collections',
                    navType: NavType.Collections,
                    child: children
                }, {
                    id: 'structures',
                    name: 'Structures',
                    expanded: true,
                    path: '/#/structures',
                    navType: NavType.Structures,
                }]
            },
            {id: 'settings', name: 'Settings', expanded: true, navType: NavType.Settings, child: []}
        ])
    }, [collections]);

    return (
        <TreeViewComponent style={{width: "100%", height: "92.8vh"}}
                           fields={
                               {
                                   dataSource: data,
                                   id: 'id',
                                   text: 'name',
                                   child: 'child',
                                   navigateUrl: 'path'
                               }
                           }
                           ref={(component) => navComponent = component}
            // nodeTemplate={(data: any) => {
            //     return (<>
            //         <div className="textcontent">
            //             <span className="treeName">{data.name}</span>
            //         </div>
            //         <div className="leftcontainer">
            //             {data.count &&
            //                 <span className="treeCount e-badge e-badge-info">{data.count}</span>
            //             }
            //         </div>
            //     </>)
            // }}
                           cssClass={cssClass}

                           drawNode={(event: DrawNodeEventArgs) => {
                               const path = ((event.nodeData.path || '') as string).substring(2);

                               const active = !!event.nodeData.path && matchPath(location.pathname, path as string);

                               if (active) {
                                   event.node.style.borderTop = '2px solid navy'
                                   event.node.style.borderBottom = '2px solid navy'
                               }
                           }}
        >
        </TreeViewComponent>
    )
}

const Main = () => {
    return (
        <div style={{width: "100%", height: "92.8vh"}}>
            <div style={{padding: "4px"}}>
                <Outlet/>
            </div>
        </div>
    )
}

// const dynamicForm = {
//     firstName: {
//         label: "First Name",
//         type: "text",
//         placeholder: "Enter your first name",
//         defaultValue: "",
//         rules: {
//             required: true,
//         },
//     },
//     lastName: {
//         label: "Last Name",
//         type: "text",
//         placeholder: "Enter your last name",
//         defaultValue: "",
//         rules: {
//             required: true,
//         },
//     },
//     gender: {
//         label: "Gender",
//         type: "radio",
//         options: ["male", "female"],
//         defaultValue: "",
//         rules: {
//             required: true,
//         },
//     },
//     profession: {
//         label: "Profession",
//         type: "dropdown",
//         options: ["Frontend Developer", "Backend Developer", "Devops Engineer"],
//         defaultValue: "",
//         rules: {
//             required: true,
//         },
//     },
//     agree: {
//         type: "checkbox",
//         label: "",
//         checkboxLabel: "I hereby agree to the terms.",
//         defaultValue: false,
//         rules: {
//             required: true,
//         },
//     },
// };

function App() {
    const location = useLocation()

    const [collections, setCollections] = useState([]);

    useEffect(() => {
        apiClient.get("/collections").then((response) => {
                setCollections(response.data);
                if (navComponent) {
                    navComponent.refresh();
                }
            }
        )
    }, [])

    useEffect(() => {
        if (navComponent) {
            navComponent.refresh();
        }
    }, [location])


    return (
        <CollectionsContext.Provider value={collections}>
            <div id="app">
                <header>
                    <ToolbarComponent id='toolbar'>
                        <ItemsDirective>
                            <ItemDirective
                                template={TdSoftLogo}
                            />
                            <ItemDirective type="Separator"/>
                            <ItemDirective
                                template={ContentaLogo}
                            />
                            <ItemDirective prefixIcon="tb-icon" text="User" align="Right"/>
                        </ItemsDirective>
                    </ToolbarComponent>
                </header>
                <main>
                    <SplitterComponent height="93vh">
                        <PanesDirective>
                            <PaneDirective size='15%' min='3%' max='75%' content={SideNav}/>
                            <PaneDirective content={Main}/>
                        </PanesDirective>
                    </SplitterComponent>
                </main>
                <footer>
                    <div style={{height: "3.2vh"}} className="div-align-center">
                        <h5>(c) 2022</h5>
                    </div>
                </footer>
                {/*<DynamicFormComponent dynamicForm={dynamicForm}/>*/}
            </div>
        </CollectionsContext.Provider>
    );
}

export default App;
