import React, {useEffect, useState} from "react";
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    Edit,
    Filter,
    GridComponent,
    Group,
    Inject,
    Page,
    Sort,
    Toolbar
} from "@syncfusion/ej2-react-grids";
import {CommandModel} from "@syncfusion/ej2-grids/src/grid/base/interface";
import {DataManager, UrlAdaptor} from "@syncfusion/ej2-data";
import {useLocation, useParams} from "react-router-dom";
import {adminBaseUrl} from "../Api";
import {ItemModel} from "@syncfusion/ej2-react-navigations";

export const CollectionDialog = () => {
    return (
        <>
        </>
    )
}

export const CollectionView = () => {
    let {collectionName} = useParams()
    const location = useLocation()
    const [data, setData] = useState({dataSource: {}});

    function titleTemplate() {
        return (<span style={{fontSize: "x-large"}}><b>{collectionName}</b> - Collection</span>);
    }

    useEffect(() => {
        setData({
            dataSource: new DataManager({
                adaptor: new UrlAdaptor(),
                crossDomain: true,
                url: adminBaseUrl + "/collections/" + collectionName
            })
        });
    }, [location, collectionName]);

    let toolbarOptions = [
        {template: titleTemplate},
        {type: 'Separator'} as ItemModel,
        {text: 'Add'}, {text: 'Edit'}, {text: 'Delete'}, {text: 'Update'}, {text: 'Cancel'},
        'Search', "Print"];
    let editOptions = {allowEditing: true, allowDeleting: true};
    let commands = [
        {type: 'Edit', buttonOption: {cssClass: 'e-flat', iconCss: 'e-edit e-icons'}},
        {type: 'Delete', buttonOption: {cssClass: 'e-flat', iconCss: 'e-delete e-icons'}},
        {type: 'Save', buttonOption: {cssClass: 'e-flat', iconCss: 'e-update e-icons'}},
        {type: 'Cancel', buttonOption: {cssClass: 'e-flat', iconCss: 'e-cancel-icon e-icons'}}
    ];

    return (
        <>
            <GridComponent dataSource={data.dataSource} allowPaging={true} allowSorting={true} allowFiltering={true}
                           editSettings={editOptions}
                           toolbar={toolbarOptions}>
                <ColumnsDirective>
                    <ColumnDirective field='_id' headerText="ID" width='100' isPrimaryKey={true}
                                     allowSearching={false}/>
                    <ColumnDirective field='Name' headerText="Name" allowSearching={false}/>
                    <ColumnDirective field='Street' headerText="Street"/>
                    <ColumnDirective headerText='Commands' width='120' commands={commands as CommandModel[]}/>
                </ColumnsDirective>
                <Inject services={[Page, Sort, Filter, Group, Toolbar, Edit, CommandColumn]}/>
            </GridComponent>
        </>
    )

}
