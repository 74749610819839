import React, {useContext, useState} from "react";
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    Edit,
    Filter,
    GridComponent,
    Group,
    Inject,
    Page,
    Sort,
    Toolbar
} from "@syncfusion/ej2-react-grids";
import {CommandModel} from "@syncfusion/ej2-grids/src/grid/base/interface";
import {DialogComponent} from "@syncfusion/ej2-react-popups";
import {EditSettingsModel} from "@syncfusion/ej2-grids/src/grid/base/grid-model";
import {CollectionsContext} from "../contexts/collections-context";
import {ClickEventArgs} from "@syncfusion/ej2-navigations";
import SchemaEditorComponent from "../components/SchemaEditorComponent";
import {genUniqueId} from "../App";
import {TextBoxComponent} from "@syncfusion/ej2-react-inputs";
import {ItemModel} from "@syncfusion/ej2-react-navigations";


const datasource: { [key: string]: Object }[] = [
    {
        id: genUniqueId(),
        name: "Person",
        isArray: true,
        isStructure: true,
        fields: [
            {id: genUniqueId(), name: "Title", type: "String"},
            {id: genUniqueId(), name: "Name", type: "String"},
        ]
    },
    {
        id: genUniqueId(),
        name: "Address",
        isStructure: true,
        fields: [
            {id: genUniqueId(), name: "Street", type: "String"},
            {id: genUniqueId(), name: "City", type: "String"},
            {id: genUniqueId(), name: "ZipCode", type: "String"},
            {id: genUniqueId(), name: "Town", type: "String"},
        ]
    },
    {
        id: genUniqueId(),
        name: "Master",
        type: "Boolean",
        isStructure: false,
        isArray: true,
        isRequired: true
    }
]
export const CollectionsView = () => {
    const collections = useContext(CollectionsContext)

    function titleTemplate() {
        return (<span style={{fontSize: "x-large"}}><b>Collections</b></span>);
    }

    let toolbarOptions = [
        {template: titleTemplate},
        {type: 'Separator'} as ItemModel,
        {text: 'Add', tooltipText: 'Add', prefixIcon: 'e-add', id: 'new'}];
    let editOptions: EditSettingsModel = {allowEditing: true, allowDeleting: true, allowAdding: true, mode: 'Dialog'};
    let commands = [
        {type: 'Edit', buttonOption: {cssClass: 'e-flat', iconCss: 'e-edit e-icons'}},
        {type: 'Delete', buttonOption: {cssClass: 'e-flat', iconCss: 'e-delete e-icons'}},
        {type: 'Save', buttonOption: {cssClass: 'e-flat', iconCss: 'e-update e-icons'}},
        {type: 'Cancel', buttonOption: {cssClass: 'e-flat', iconCss: 'e-cancel-icon e-icons'}}
    ];

    const [showCollectionDialog, setShowCollectionDialog] = useState(false);
    let buttons = [
        {
            buttonModel: {
                content: 'OK',
                cssClass: 'e-flat',
                isPrimary: true,
            },
            'click': () => {
                setShowCollectionDialog(false);
            }
        },
        {
            buttonModel: {
                content: 'Cancel',
                cssClass: 'e-flat'
            },
            'click': () => {
                setShowCollectionDialog(false);
            }
        }
    ];

    return (
        <>
            <GridComponent dataSource={collections} allowPaging={true} allowSorting={true}
                           editSettings={editOptions}
                           toolbar={toolbarOptions}
                           toolbarClick={(event: ClickEventArgs) => {
                               if (event.item.id === 'new') {
                                   setShowCollectionDialog(true)
                               }
                           }}>
                <ColumnsDirective>
                    <ColumnDirective field='name' headerText="Name" width='100'/>
                    <ColumnDirective field='documentCount' headerText="Documents" width='200' format="N"
                                     textAlign="Right"/>
                    <ColumnDirective field='createdBy' headerText="Created By" width='200'/>
                    <ColumnDirective field='creationDate' headerText="Creation Date" width='200' format='yMd'
                                     type="date"/>
                    <ColumnDirective field='updatedBy' headerText="Updated By" width='200'/>
                    <ColumnDirective field='updateDate' headerText="Update Date" width='200'/>
                    <ColumnDirective headerText='Commands' width='120' commands={commands as CommandModel[]}/>
                </ColumnsDirective>
                <Inject services={[Page, Sort, Filter, Group, Toolbar, Edit, CommandColumn]}/>
            </GridComponent>
            <DialogComponent visible={showCollectionDialog}
                             close={() => setShowCollectionDialog(false)}
                             width='70vh'
                             target="#app"
                             header='Edit Collection'
                             isModal={true}
                             allowDragging={true}
                             enableResize={true}
                             showCloseIcon={true}
                             buttons={buttons}>
                <form>
                    <div className="control_wrapper">
                        <div>
                            <TextBoxComponent placeholder="Name" showClearButton={true} floatLabelType="Auto"/>
                        </div>
                        <div>
                            <SchemaEditorComponent datasource={datasource}/>
                        </div>
                    </div>
                </form>
            </DialogComponent>
        </>
    );

}

